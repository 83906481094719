@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200&display=swap');

html, body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: pullout, sans-serif;
  color: #E9ECEF;
  font-size: 60px;
  /* background-color: #32373B; */
  background-color: #1A1A1D;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  /* overflow-x: hidden; */
}

@font-face {
  font-family: 'pullout';
  src: url('/src/Inter/static/Inter-ExtraLight.ttf'); 
}

* {
  min-height: 0;
  min-width: 0;
}

.bg-image {
  
  z-index: 10;
  height: 100vh;
}

section {
  /* background-image: url('backgrounds/velvet.jpg');
  background-size: cover;
  background-position: center; */
  scroll-snap-align: start;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.test-div {
  scroll-snap-align: start;
  height: 100vh;
  position: relative;
}


.bot-btn {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  z-index: 1;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  top: 85%; 
}

svg {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width:100%;
  height:100%;
}

.title {
  text-align: center;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;

  justify-items: center;
  align-items: center;
}

.container-job {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  column-gap: 30%;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.under-hover {
  position: relative;
  color: #E9ECEF;
  cursor: pointer;
  padding-bottom: 10px;
}
.under-hover:after {    
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #fff;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.under-hover:hover:after { 
  width: 100%; 
  left: 0; 
}

.list-skills {
  padding: 0;
  list-style: none;
  font-size: 17px;
}

.item {
  /* box-sizing: border-box; */
  width: 290px;
  height: 400px;
}

.greeting {
  font-size: 60px;
}

.gt {
  opacity: 0;
  filter: blur(5px);
}

.intro {
  font-size: 26px;
  background-color: #1A1A1D;
}

.work {
  font-size: 30px;
  background-color: #1A1A1D;
  display: grid;
  grid-template-rows: 50% 40%;
  align-items: center;
  justify-content: center;
}

.work-card {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
  height: 225px;
  width: 550px;
  font-size: 27px;
  border: 2px solid white;
  border-radius: 15%;
  padding: 1%; 
}

.logo {
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;

}



.center {
  text-align: center;
}

.work-page-title {
  font-size: 35px;
}

.work-title {
  font-size: 110%;
}

.work-detail {
  margin-left: 25px;
  margin-right: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* padding: 0 10px 0 10px; */

}

.work-term{
  font-size: 60%;
}

.work-text {
  font-size: 60%;
}

.work-skill{
  font-size: 40%;
}

.scroll-div {
}


.languages {
  font-size: 23px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}
.languages-title {
  font-size: 33px;
}

.project-card {
  background-color: #1A1A1D;
  width: 250px;
  height: 250px;
  font-size: 20px;
  border-radius: 50%;
  color: #E9ECEF;
  display: flex;
  border: solid #FFFFFF;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 50px 0 50px;
  cursor: pointer;
}

.proj-card {
  /* width: 450px; */
  /* width: min(80%, 450px); */
  width: clamp(50%, 350px, 75%);
  height: 500px;
  font-size: 20px;
  border-radius: 3%;
  color: #E9ECEF;
  /* display: flex;
  flex-flow: column wrap; */
  border: solid #FFFFFF;
  /* align-items: center;
  justify-content: center;
  text-align: center; */
  /* padding: 0 50px 0 50px; */
}

.p-title-size {
  font-size: 28px;
  margin-top: 10px;
}

.p-desc-size {
  font-size: 15px;
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
  /* margin: 20px 10px 10px 40px; */
}
.p-skills-size {
  font-size: 13px;
  margin-top: 20px;
  margin-left: 5px;
  margin-right: 5px;
}

.proj-container {
  /* display: flexbox;
  justify-content: center;
  align-items: center; */
  /* display: flex;
  flex-wrap: wrap; */

  /* display: grid; */
  /* width: 100%; */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* flex-wrap: wrap; */
  justify-content: space-evenly;
  row-gap: 22px;
  column-gap: 22px;
}

.projects {
  font-size: 80%;
  color: #FFFFFF;
  background-color: #1A1A1D;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;


  /* grid-template-rows: 1fr 2fr; */
  /* display: flex;
  ;
  align-items: center; */
  /* margin: auto; */
}

.content {
  margin: 10px 10px 10px 10px;
}

.project-skills {
  font-size: 60%;
}

.project-desc {
  font-size: 75%;
  /* padding-left: 10%;
  padding-right: 10%; */
}

.project-title {
  /* margin-top: 70px; */
  font-size: 30px;
}

.slider-container {
  max-width: 100vw;
  width: 100%;
  max-height: 100vh;
  height: 100%;
  overflow: hidden;
  color: #E9ECEF;
  background-color: #1A1A1D;
}

.griddy {
  display: flex;
  flex-flow: row wrap;
  /* grid-template-columns: 1fr 2fr 1fr; */
  align-items: center;
  justify-content: center;
}

.swiper-button {
  /* position: absolute;
  top: 52%; */
  /* z-index: 10; */
  cursor: pointer;
}
.swiper-button svg {
  width: 1.5rem;
  height: 1.5rem;
}

.image-swiper-button-prev {
  /* left: 140px; */
  color:#E9ECEF;
}
.image-swiper-button-next {
  /* right: 140px; */
  color:#E9ECEF;
  /* display: flex; */
  justify-content: center;
  align-items: center;
}
.swiper-button-disabled {
  opacity: 0.5;
}

.swiper {
  width: 80%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;


  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.highlight {
  display: inline;
  padding: .25em 0;
  background: #1A1A1D;
  color: #E9ECEF;
  border-radius: 10px;
  font-size: 40px;
  box-shadow: .5em 0 0 #1A1A1D, -.5em 0 0 #1A1A1D;
}

.me {
  font-size: 50%;
  background-color: #f5cda7;
}


.misbah {
  width: 300px;
  height: auto;
  border-radius: 40%;
  /* margin-right: 50px; */
}


a {
  color: #E9ECEF; 
}

.about-links {
  text-decoration: none;
}

.about-links:visited { 
  text-decoration: none; 
  color: #E9ECEF; 
 }

@media (max-width: 1100px) {
  .greeting {
    font-size: 50px; 
  }

  .intro {
    font-size: 20px;
  }

  /* controls languages sizes
  .list-skills {
    font-size: 12px;
  }
  .item {
      width: 230px;
      height: 300px;
  }
  .grid-container {
    font-size: 18px;
  } */

  .projects {
    display: flex;
    flex-flow: row wrap;
  }

  .project-card {
    width: 200px;
    height: 200px;
    font-size: 33px;
    padding: 0 50px 0 50px;
  }

  .proj-container {
    /* height: 500px; */
    height: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .project-card {
    font-size: 18px;
  }

  .highlight {
    font-size: 35px;
  }
}


@media (max-width: 950px) {

  /* controls languages sizes */
  .list-skills {
    font-size: 12px;
  }
  .item {
      width: 200px;
      height: 230px;
  }
  .grid-container {
    font-size: 16px;
  }

  .proj-container {
    grid-template-columns: repeat(2, 1fr);
    height: 600px;
  }
  .project-card {
    width: 150px;
    height: 150px;
    font-size: 18px;
    padding: 0 50px 0 50px;
  }

  .projects {
    font-size: 50%;
    display: flex;
    flex-flow: row wrap;
  }
  .project-card {
    font-size: 20%;
  }

  .highlight {
    font-size: 70%;
  }
}

@media (max-width: 630px) {
  .intro {
    font-size: 18px;
  }
  html, body {
    scroll-snap-type: y proximity;
  }

  .logo{
    width: 100px;
    height: auto;
  }

  /* controls languages sizes */
  .list-skills {
    font-size: 11px;
  }
  .item {
      width: 150px;
      height: 200px;
  }
  .grid-container {
    font-size: 14px;
    margin-bottom: 40px;
  }

  .proj-container {
    grid-template-columns: repeat(2, 1fr);
    border: solid gray;
    height: 600px;
  }

  .project-card {
    width: 200px;
    height: 170px;
    font-size: 18px;
    padding: 0 30px 0 30px;
  }

  .proj-container {
    /* height: 500px; */
    border: dashed blue;
    display: flex;
    flex-wrap: wrap;
  }

  .project-card {
    font-size: 16px;
  }

  .highlight {
    font-size: 30px;
  }
}

@media (max-width: 450px) {

  .logo{
    width: 80px;
    height: auto;
  }

  /* controls languages sizes */
  .list-skills {
    font-size: 11px;
  }
  .item {
      width: 100px;
      height: 150px;
  }
  .grid-container {
    font-size: 13px;
    margin-bottom: 60px;
  }

  .languages-title {
    font-size: 27px;
  }


  .work-card {
    height: 300px;
    font-size: 25px;
    border: 2px solid white;
    border-radius: 15%;
    padding: 1%; 
  }


  .proj-container {
    /* grid-template-columns: repeat(1, 1fr); */
    border: solid gray;
    height: 600px;
  }

  .project-card {
    width: 200px;
    height: 200px;
    font-size: 20px;
    padding: 0 30px 0 30px;
  }

  .proj-container {
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    width: 300px;
    height: 400px;
    overflow: hidden;
    overflow-y: scroll;
  }


  .project-card {
    font-size: 17px;
  }

  .highlight {
    font-size: 26px;
  }
}